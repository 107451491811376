<template>
  <div class="sections-container">
    <el-tabs
      v-model="activeTab"
      @tab-click="handleTabClick(activeTab)"
      class="middle"
    >
      <el-tab-pane
        v-for="item in tabs"
        :key="item.label"
        :label="item.label"
        :name="item.name"
      >
      <div class="introduction-content">
        <div class="container">
          <p>Introduction</p>
          <div>
            <el-button type="primary" @click="isEdit = true">Edit</el-button>
            <el-button type="primary" @click="isEdit = false">Save</el-button>
          </div>
        </div>
        <div>
          <el-input
            v-model="introduction_content"
            type="textarea"
            placeholder=""
            :disabled="!isEdit"
            :autosize="{ minRows: 5 }"
          ></el-input>
        </div>
      </div>

      <div class="rubric-content">
        <div class="container">
          <p>Scoring Rubric</p>
          <div>
            <el-button type="primary">Add a Rubric</el-button>
          </div>
        </div>
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          :header-cell-style="{ background: '#FF6A64', color: '#ffffff' }"
        >
          <el-table-column prop="criteria" label="Criteria" min-width="70">
          </el-table-column>
          <el-table-column prop="description" label="Description" min-width="280">
          </el-table-column>
          <el-table-column prop="score" label="Score" min-width="80">
          </el-table-column>
          <el-table-column fixed="right" label="Operation" width="100">
            <template slot-scope="scope">
              <el-button
                @click="handleEdit(scope.row)"
                type="text"
                size="small"
                >Edit</el-button
              >
              <span style="color: #eb7269"> | </span>
              <el-button
                @click="handleDelete(scope.row)"
                type="text"
                size="small"
                >Delete</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      </el-tab-pane>
    </el-tabs>

    <div class="customer-info">
      <customerInfo />
    </div>
  </div>
</template>

<script>
import baseCard from "@/components/baseCard.vue";
import customerInfo from "@/components/customerInfo.vue";
import { reviewArticle, getAllArticles } from "../api/index";
import { tabList } from '../utils/commonList'
export default {
  name: "home",
  components: {
    baseCard,
    customerInfo,
  },
  data() {
    return {
      activeTab: "Campus",
      tabs: tabList,
      isEdit: false,
      introduction_content: '',
      tableData: [
        {
          criteria: "24Q3",
          description: "xxxx",
          score: 9.0,
        },
        {
          criteria: "24Q3",
          description: "xxxx",
          score: 9.0,
        },
      ],
    };
  },
  methods: {
    handleTabClick(tab, event) {
      console.log(tab, event);
    },
    handleEdit() {

    },
    handleDelete() {

    }
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.sections-container {
  height: 100%;
  display: flex;
  .middle {
    flex: 1;
    margin-right: 20px;
  }
  .introduction-content {
    margin-bottom: 20px;
    .container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    /deep/ .el-textarea.is-disabled .el-textarea__inner {
      background: #fff;
      border-color: #fff;
      cursor: unset;
    }

  }
  .rubric-content {
    .container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
  }

  
}
</style>
